import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from 'app/services/general.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gestioneventos',
  templateUrl: './gestioneventos.component.html',
  styleUrls: ['./gestioneventos.component.css']
})
export class GestioneventosComponent implements OnInit {
  contactos:any =[]
  search:string=''
  page:number=0
  formulariocontactol:FormGroup;
  id:any
  constructor(private servicio:GeneralService,private formulario: FormBuilder,private route: ActivatedRoute) { 
    this.formulariocontactol = this.formulario.group(
      {
        actividad:['',Validators.required],
        date:['',Validators.required],      
         
      }
  )
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getactivitys()
  }


  sendform(){
    let data =this.formulariocontactol.value
    data.client=this.id
    this.servicio.createevent(data).subscribe((res:any)=>{
      console.log(res);
      this.getactivitys()
      this.formulariocontactol.reset()
      
    },err=>{
      console.log(err);
      
    })
    
  }

  inactivar(id,status){
let data={
  id,
  status: status==1?0:1
}

this.servicio.changestatusevent(data).subscribe((res:any)=>{
  console.log(res);
  this.getactivitys()
  
},err=>{
  console.log(err);
  
})


  }

  getactivitys() {
    let data ={
      id:this.id
    }

   this.servicio.getactivitys(data).subscribe((res:any)=>{
    console.log(res);
    this.contactos = res
    
   },err=>{console.log(err);
   })
  }

  ngAfterViewInit() {
    // Obtener los elementos y asegurarse de que son del tipo correcto
    const modal = document.getElementById('myModal') as HTMLDivElement;
    const btn = document.getElementById('openModalBtn') as HTMLButtonElement;
    const span = document.getElementsByClassName('close')[0] as HTMLSpanElement;
  
    // Abrir el modal cuando se hace clic en el botón
    btn.onclick = function() {
      modal.style.display = 'block';
    };
  
    // Cerrar el modal cuando se hace clic en la "X"
    span.onclick = function() {
      modal.style.display = 'none';
    };
  
    // Cerrar el modal si se hace clic fuera del contenido del modal
    window.onclick = function(event: MouseEvent) {
      if (event.target === modal) {
        modal.style.display = 'none';
      }
    };
  }

  
  
    
      
      nextpage(){
        this.page +=5
            }
        
            prevpage(){
              if(this.page > 0){
        this.page -=5
              }
            }
        
        
        
        
            buscar(search){
              this.page =0
        this.search = search
            }

}
