import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneralService } from 'app/services/general.service';


@Component({
  selector: 'app-gestionclientes',
  templateUrl: './gestionclientes.component.html',
  styleUrls: ['./gestionclientes.component.css']
})
export class GestionclientesComponent implements OnInit {
  contactos:any
  search:string=''
  page:number=0
  formulariocontactol:FormGroup;
  
  constructor(private servicio:GeneralService,private formulario: FormBuilder) { 
    this.formulariocontactol = this.formulario.group(
      {
          nombre:['',Validators.required],
          apellidos:['',Validators.required],
          email:['',[Validators.required,Validators.email]],
          identificacion:['',Validators.required],
          access :['',Validators.required],
          access_code  :['',Validators.required],
      }
  )
  }

  ngOnInit(): void {
    this.getclientes()
  }


  sendform(){
    this.servicio.createcliente(this.formulariocontactol.value).subscribe((res:any)=>{
      console.log(res);
      this.getclientes()
      this.formulariocontactol.reset()
      
    },err=>{
      console.log(err);
      
    })
    
  }

  inactivar(id,status){
let data={
  id,
  status: status==1?0:1
}

this.servicio.changestatusclient(data).subscribe((res:any)=>{
  console.log(res);
  this.getclientes()
  
},err=>{
  console.log(err);
  
})


  }

  getclientes() {
   this.servicio.getclientes().subscribe((res:any)=>{
    console.log(res);
    this.contactos = res
    
   },err=>{console.log(err);
   })
  }

  ngAfterViewInit() {
    // Obtener los elementos y asegurarse de que son del tipo correcto
    const modal = document.getElementById('myModal') as HTMLDivElement;
    const btn = document.getElementById('openModalBtn') as HTMLButtonElement;
    const span = document.getElementsByClassName('close')[0] as HTMLSpanElement;
  
    // Abrir el modal cuando se hace clic en el botón
    btn.onclick = function() {
      modal.style.display = 'block';
    };
  
    // Cerrar el modal cuando se hace clic en la "X"
    span.onclick = function() {
      modal.style.display = 'none';
    };
  
    // Cerrar el modal si se hace clic fuera del contenido del modal
    window.onclick = function(event: MouseEvent) {
      if (event.target === modal) {
        modal.style.display = 'none';
      }
    };
  }

  
  traercontactos(){

  
    
    
      }
    
    
    
      
      nextpage(){
        this.page +=5
            }
        
            prevpage(){
              if(this.page > 0){
        this.page -=5
              }
            }
        
        
        
        
            buscar(search){
              this.page =0
        this.search = search
            }
  
}
